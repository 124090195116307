import { cn } from '~/utils/css/css';

import { VariantProps, cva } from 'class-variance-authority';
import React, { ReactNode } from 'react';

const fieldContainerVariants = cva('w-full', {
  variants: {
    layout: {
      horizontal: 'w-full flex items-center gap-2',
      vertical: 'w-full grid gap-2',
      grid: 'grid grid-cols-2',
    },
    reverse: {
      true: 'flex-row-reverse',
    }
  },
  defaultVariants: {
    layout: 'vertical',
  },
});

interface FieldContainerProps extends VariantProps<typeof fieldContainerVariants> {
  children: ReactNode;
  className?: string;
}

export const FieldContainer = ({ children, className, layout, reverse }: FieldContainerProps) => {
  return <div className={cn(fieldContainerVariants({ layout, reverse }), className)}>{children}</div>;
};
